
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

interface Candidate {
  _id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  profile_image: string;
  address?: string;
}
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined
  },
  setup (props, { emit }) {
    const personalFormRef = ref()
    const personalRules = validate.personalDetailsValidation
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const personalForm: UnwrapRef<Candidate> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      address: ''
    })
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD
    const fileList = ref<FileItem[]>([])
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value.length > 0) {
        const s3Response: any = fileList.value[0].response
        fileList.value = newFileList
        if (s3Response) {
          personalForm.profile_image = s3Response.Location
        }
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
      personalForm.profile_image = ''
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const updateProfile = async (data: any) => {
      try {
        data.address = data.address._id
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.updateProfile(profile._id, data)
        Object.assign(personalForm, responce.data)
        profile.first_name = responce.data.first_name
        profile.last_name = responce.data.last_name
        profile.profile_image = responce.data.profile_image
        localStorage.setItem('profile', JSON.stringify(profile))
        emit('updateMenu')
        await notify('Success', 'Profile update successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      personalFormRef.value
        .validate()
        .then(() => {
          updateProfile(personalForm)
        })
        .catch((error: ValidateErrorEntity<Candidate>) => {
          console.log('error', error)
        })
    }
    const getProfileDetails = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.getProfileDetails(profile._id)
        Object.assign(personalForm, responce.data)
        if (personalForm.profile_image) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: personalForm.profile_image
            }
          )
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getProfileDetails()
    })
    return {
      personalFormRef,
      personalForm,
      personalRules,
      updateProfile,
      validateForm,
      imageUploadUrl,
      handleRemove,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      handleChange,
      headers
    }
  }
})
