<template>
  <div class="main">
    <div class="personal-Details content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Settings</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Personal Details</h4>
        </a-col>
      </a-row>
      <div class="body">
        <a-form ref="personalFormRef" :model="personalForm" :rules="personalRules" layout="vertical">
          <a-form-item label="First Name" required has-feedback name="first_name">
            <a-input v-model:value="personalForm.first_name" size="large" type="text" placeholder="Enter First Name" allow-clear autocomplete="off" />
          </a-form-item>
          <a-form-item label="Last Name" required has-feedback name="last_name">
            <a-input v-model:value="personalForm.last_name" size="large" type="text" placeholder="Enter Last Name" allow-clear autocomplete="off" />
          </a-form-item>
          <a-form-item label="Phone" has-feedback name="phone">
            <a-input type="number" size="large" placeholder="Enter Phone Number" v-model:value="personalForm.phone" allow-clear autocomplete="off" />
          </a-form-item>
          <a-form-item label="Email" has-feedback name="email">
            <a-input type="text" size="large" placeholder="Enter Email" v-model:value="personalForm.email" allow-clear autocomplete="off" disabled />
          </a-form-item>
          <a-form-item label="Profile Image" name="image">
            <a-upload :action="imageUploadUrl" :headers="headers" list-type="picture-card" v-model:file-list="fileList"
              :multiple="false" @preview="handlePreview" @change="handleChange" :remove="handleRemove">
              <div v-if="fileList.length <= 0">
                <plus-outlined />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-form>

        <a-button type="primary" class="updateBtn" @click.prevent="validateForm">Update</a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import commonServices from '../../../services/common'

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

interface Candidate {
  _id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  profile_image: string;
  address?: string;
}
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined
  },
  setup (props, { emit }) {
    const personalFormRef = ref()
    const personalRules = validate.personalDetailsValidation
    const previewVisible = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const personalForm: UnwrapRef<Candidate> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      address: ''
    })
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD
    const fileList = ref<FileItem[]>([])
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value.length > 0) {
        const s3Response: any = fileList.value[0].response
        fileList.value = newFileList
        if (s3Response) {
          personalForm.profile_image = s3Response.Location
        }
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
      personalForm.profile_image = ''
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const updateProfile = async (data: any) => {
      try {
        data.address = data.address._id
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.updateProfile(profile._id, data)
        Object.assign(personalForm, responce.data)
        profile.first_name = responce.data.first_name
        profile.last_name = responce.data.last_name
        profile.profile_image = responce.data.profile_image
        localStorage.setItem('profile', JSON.stringify(profile))
        emit('updateMenu')
        await notify('Success', 'Profile update successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      personalFormRef.value
        .validate()
        .then(() => {
          updateProfile(personalForm)
        })
        .catch((error: ValidateErrorEntity<Candidate>) => {
          console.log('error', error)
        })
    }
    const getProfileDetails = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await profileService.getProfileDetails(profile._id)
        Object.assign(personalForm, responce.data)
        if (personalForm.profile_image) {
          fileList.value.push(
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: personalForm.profile_image
            }
          )
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getProfileDetails()
    })
    return {
      personalFormRef,
      personalForm,
      personalRules,
      updateProfile,
      validateForm,
      imageUploadUrl,
      handleRemove,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      handleChange,
      headers
    }
  }
})
</script>

<style lang="scss">
.personal-Details {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    .has-error .ant-form-explain {
      text-align: left;
    }
    .ant-input-affix-wrapper{
      border-radius: 5px;
    }
    .ant-form {
      display: flex;
      flex-flow: wrap;
      width: 65%;
      margin: 50px auto;
      justify-content: space-between;
      label {
        color: #646464 !important;
      }
      .ant-upload-picture-card-wrapper {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        .ant-upload-select-picture-card {
          margin-bottom: 0;
          background-color: #d9d9d9;
          border: none;
        }
      }
    }
    .ant-form-vertical .ant-form-item {
      width: 48%;
    }
    .updateBtn {
      background: #72CF97;
      border: 1px solid #72CF97;
      border-radius: 5px;
      width: 250px;
      margin: 0 0 30px 0;
    }
  }
}
</style>
